import React from "react";
// import { useEffect, useState } from "react";
import Carousel from "../../components/Carousel/Carousel";
import ProductReviewsSlide from "./ProductReviewsSlide";
import useTranslations from "../../hoc/useTranslations";

import "./ProductReviews.scss";

const ProductReviews = () => {
	// const [progressValue, setProgressValue] = useState(0);

	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		if (progressValue < 100) {
	// 			setProgressValue(progressValue + 1);
	// 		}
	// 	}, 45);

	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// }, [progressValue]);

	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		autoplaySpeed: 4000,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		// afterChange: () => setProgressValue(0),
	};

	const {
		product: { reviews },
	} = useTranslations();

	return (
		<section className="section section__product-reviews">
			<div className="product-reviews__carousel">
				<Carousel settings={settings}>
					{reviews.map(({ description, title }) => (
						<ProductReviewsSlide key={title} name={title} description={description} />
					))}
				</Carousel>
			</div>
			{/* <progress value={30} max={100} className="product-reviews__progress"></progress> */}
		</section>
	);
};

export default ProductReviews;
