import React from "react";
import PropTypes from "prop-types";

import QuoteIcon from "../../assets/icons/quote.svg";

const ProductReviewsSlide = ({ description, name }) => {
	return (
		<figure className="product-reviews__slide">
			<div className="product-reviews__slide--decorator">
				<QuoteIcon />
			</div>
			<blockquote className="product-reviews__slide--quote">{description}</blockquote>
			<figcaption className="product-reviews__slide--author">{name}</figcaption>
		</figure>
	);
};

ProductReviewsSlide.propTypes = {
	description: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

export default ProductReviewsSlide;
