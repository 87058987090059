import React from "react";
import Heading from "../../components/Heading/Heading";
import Description from "../../components/Description/Description";
import useTranslations from "../../hoc/useTranslations";

import TimeSVG from "../../assets/icons/product_time.svg";
import AutoSVG from "../../assets/icons/product_auto.svg";
import SourceSVG from "../../assets/icons/product_source.svg";
import SecuritySVG from "../../assets/icons/product_security.svg";
import NoTechSVG from "../../assets/icons/product_notech.svg";
import SetupSVG from "../../assets/icons/product_setup.svg";
import ScaleSVG from "../../assets/icons/product_scale.svg";
import SearchSVG from "../../assets/icons/product_search.svg";
import AnalyticsSVG from "../../assets/icons/product_analytics.svg";

import "./ProductAbilities.scss";

const icons = [
	TimeSVG,
	AutoSVG,
	SourceSVG,
	SecuritySVG,
	NoTechSVG,
	SetupSVG,
	ScaleSVG,
	SearchSVG,
	AnalyticsSVG,
];

const ProductAbilities = () => {
	const {
		product: {
			abilities: { title, items },
		},
	} = useTranslations();

	const superItems = items.map((item, index) => ({
		...item,
		SVG: icons[index],
	}));

	return (
		<section className="section section__product-abilities">
			<Heading className="product-abilities__title" type={2}>
				{title}
			</Heading>
			<div className="product-abilities__items">
				{superItems.map(({ title, description, SVG }, index) => (
					<article key={title + index} className="product-abilities__section">
						{SVG ? (
							<div className="product-abilities__section--icon">
								<SVG />
							</div>
						) : null}
						<Heading type={3} className="product-abilities__section--title">
							{title}
						</Heading>
						<Description className="product-abilities__section--description">
							{description}
						</Description>
					</article>
				))}
			</div>
		</section>
	);
};

export default ProductAbilities;
