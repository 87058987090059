import React from "react";
import PropTypes from "prop-types";
import Description from "../../components/Description/Description";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import useTranslations from "../../hoc/useTranslations";

import "./ProductCTA.scss";

const ProductCTA = ({ toggleModal }) => {
	const {
		getDemoButton,
		product: {
			cta: { title, description },
		},
	} = useTranslations();

	return (
		<section className="section section__product-cta">
			<Heading type={2}>{title}</Heading>
			<Description>{description}</Description>
			<Button onClick={toggleModal}>{getDemoButton}</Button>
		</section>
	);
};

ProductCTA.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};

export default ProductCTA;
