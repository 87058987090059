import React from "react";
import useTranslations from "../../hoc/useTranslations";

import "./Facts.scss";

const Facts = () => {
	const {
		product: { facts },
	} = useTranslations();

	return (
		<section className="section section__facts">
			{facts.map(({ value, text }) => (
				<article key={text} className="facts__article">
					<span className="facts__article--number">{value}</span>
					<p className="facts__article--paragraph">{text}</p>
				</article>
			))}
		</section>
	);
};

export default Facts;
