import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";
import Product from "../containers/Product/Product";

const ProductPage = ({ pageContext, toggleModal }) => (
	<Layout pageContext={pageContext}>
		<Product toggleModal={toggleModal} />
	</Layout>
);

ProductPage.propTypes = {
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
	toggleModal: PropTypes.func.isRequired,
};

export default ProductPage;
