import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { PAGE } from "../../helpers/constants";
import useTranslations from "../../hoc/useTranslations";
import SEO from "../../components/seo";
import Button from "../../components/Button/Button";
import Heading from "../../components/Heading/Heading";
import Carousel from "../../components/Carousel/Carousel";
import ProductDetails from "../ProductDetails/ProductDetails";
import KnowledgeBaseIntegrations from "../KnowledgeBaseIntegrations/KnowledgeBaseIntegrations";
import ProductAbilities from "../ProductAbilities/ProductAbilities";
import ProductReviews from "../ProductReviews/ProductReviews";
import ProductCTA from "../ProductCTA/ProductCTA";
import Facts from "../Facts/Facts";
// import Faq from "../Faq/Faq";
import { carouselSettings } from "../Header/Header";
import OverlapingCTA from "./OverlapingCTA";

import "./Product.scss";

const Product = ({ toggleModal }) => {
	const data = useStaticQuery(graphql`
		query {
			hero: file(relativePath: { eq: "product_hero.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			innogy: file(relativePath: { eq: "innogy_carousel.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			woodward: file(relativePath: { eq: "woodward_carousel.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			poznan: file(relativePath: { eq: "poznan_carousel.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			santander: file(relativePath: { eq: "santander_carousel.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			coface: file(relativePath: { eq: "coface_carousel.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			bocian: file(relativePath: { eq: "bocian_carousel.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	const { fluid: HeroImage } = data.hero.childImageSharp;
	const { fluid: innogy } = data.innogy.childImageSharp;
	const { fluid: woodward } = data.woodward.childImageSharp;
	const { fluid: poznan } = data.poznan.childImageSharp;
	const { fluid: santander } = data.santander.childImageSharp;
	const { fluid: coface } = data.coface.childImageSharp;
	const { fluid: bocian } = data.bocian.childImageSharp;

	const carouselImages = [
		{
			image: santander,
			type: "fluid",
		},
		{
			image: woodward,
			type: "fluid",
		},
		{
			image: innogy,
			type: "fluid",
		},
		{
			image: poznan,
			type: "fluid",
		},
		{
			image: coface,
			type: "fluid",
		},
		{
			image: bocian,
			type: "fluid",
		},
	];

	const {
		product: {
			header: { heading, paragraph },
			// faqs,
		},
		getDemoButton,
	} = useTranslations();

	return (
		<>
			<SEO page={PAGE.PRODUCT} />
			<div className="product">
				<header className="header">
					<div className="header__container">
						<Heading type={1}>{heading}</Heading>
						<p className="header__paragraph">{paragraph}</p>
						<Button classNames={["header__button"]} onClick={() => toggleModal()}>
							{getDemoButton}
						</Button>
					</div>
					<div className="header__container">
						<Img
							className="header__image"
							fluid={HeroImage}
							title="HeroImage"
							alt="HeroImage"
							fadeIn={false}
							loading="eager"
						/>
					</div>
				</header>
				<main>
					<Facts />
					<div className="product__carousel">
						<Carousel settings={carouselSettings} images={carouselImages} />
					</div>
					<ProductDetails />
					<OverlapingCTA toggleModal={toggleModal} />
					<KnowledgeBaseIntegrations />
					<ProductReviews />
					<ProductCTA toggleModal={toggleModal} />
					<ProductAbilities />
					{/* <Faq faqs={faqs} /> */}
				</main>
			</div>
		</>
	);
};

Product.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};

export default Product;
