import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Button from "../../components/Button/Button";
import Heading from "../../components/Heading/Heading";
import useTranslations from "../../hoc/useTranslations";

import GrowArrowSVG from "../../assets/icons/grow-arrow.svg";

const OverlapingCTA = ({ toggleModal }) => {
	const { getDemoButton } = useTranslations();

	const data = useStaticQuery(graphql`
		query {
			NinetyPercent: file(relativePath: { eq: "90-percent.png" }) {
				childImageSharp {
					fluid(maxWidth: 330, quality: 60) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	const NinetyPercent = data.NinetyPercent.childImageSharp.fluid;

	const {
		product: {
			overlaping: { title },
		},
	} = useTranslations();

	return (
		<section className="section section__overlaping-cta">
			<div className="overlaping-cta">
				<header className="overlaping-cta__header">
					<Heading type={2} className="overlaping-cta__header--heading">
						{title}
					</Heading>
					<Button classNames={["overlaping-cta__header--button"]} onClick={toggleModal}>
						{getDemoButton}
					</Button>
				</header>
				<Img
					fluid={NinetyPercent}
					alt="90%"
					className="overlaping-cta__percent"
					fadeIn={false}
					loading="eager"
					title="90%"
				/>
				<GrowArrowSVG className="overlaping-cta__arrow-bg" />
			</div>
		</section>
	);
};

OverlapingCTA.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};

export default OverlapingCTA;
