import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import Description from "../../components/Description/Description";
import Heading from "../../components/Heading/Heading";
import useTranslations from "../../hoc/useTranslations";

import "./KnowledgeBaseIntegrations.scss";

import CMSSVG from "../../assets/icons/icon_cms.svg";
import NextCloudSVG from "../../assets/icons/icon_nextcloud.svg";
import WebsitesSVG from "../../assets/icons/icon_websites.svg";
import IntranetSVG from "../../assets/icons/icon_intranet.svg";

const KnowledgeBaseIntegrations = () => {
	const data = useStaticQuery(graphql`
		query {
			googleDrive: file(relativePath: { eq: "icon-gdrive.png" }) {
				childImageSharp {
					fluid(maxWidth: 64, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			livechat: file(relativePath: { eq: "icon-livechat.png" }) {
				childImageSharp {
					fluid(maxWidth: 64, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			sharepoint: file(relativePath: { eq: "icon-sharepoint.png" }) {
				childImageSharp {
					fluid(maxWidth: 64, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			slack: file(relativePath: { eq: "icon-slack.png" }) {
				childImageSharp {
					fluid(maxWidth: 64, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	const { fluid: googleDrive } = data.googleDrive.childImageSharp;
	const { fluid: livechat } = data.livechat.childImageSharp;
	const { fluid: sharepoint } = data.sharepoint.childImageSharp;
	const { fluid: slack } = data.slack.childImageSharp;

	const addIcon = type => {
		switch (type) {
			case "slack":
				return {
					type: "image",
					source: slack,
				};
			case "intranet":
				return {
					type: "svg",
					SVG: () => <IntranetSVG className="knowledge-integrations__app--icon svg" />,
				};
			case "cms":
				return {
					type: "svg",
					SVG: () => <CMSSVG className="knowledge-integrations__app--icon svg" />,
				};
			case "sharepoint":
				return {
					type: "image",
					source: sharepoint,
				};
			case "gdrive":
				return {
					type: "image",
					source: googleDrive,
				};
			case "nextcloud":
				return {
					type: "svg",
					SVG: () => <NextCloudSVG className="knowledge-integrations__app--icon svg" />,
				};
			case "livechat":
				return {
					type: "image",
					source: livechat,
				};
			case "web":
				return {
					type: "svg",
					SVG: () => <WebsitesSVG className="knowledge-integrations__app--icon svg" />,
				};
			default:
				return {};
		}
	};

	const {
		product: {
			knowledge_integrations: { title, description, items },
		},
	} = useTranslations();

	const itemsWithIcons = items.map(item => ({
		...item,
		icon: addIcon(item.short_name),
	}));

	return (
		<section className="section section__knowledge-integrations">
			<Heading className="knowledge-integrations__heading" type={2}>
				{title}
			</Heading>
			<Description className="knowledge-integrations__description">{description}</Description>
			<div className="knowledge-integrations__apps">
				{itemsWithIcons.map(({ name, description, icon: { type, source, SVG } }) => (
					<article className="knowledge-integrations__app" key={name}>
						{type === "image" && (
							<Img
								fluid={source}
								className="knowledge-integrations__app--icon"
								fadeIn={false}
								alt={name}
								loading="eager"
								title={name}
							/>
						)}
						{type === "svg" && <SVG />}
						<Heading className="knowledge-integrations__app--name" type={3}>
							{name}
						</Heading>
						<Description className="knowledge-integrations__app--description">
							{description}
						</Description>
					</article>
				))}
			</div>
		</section>
	);
};

export default KnowledgeBaseIntegrations;
