import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import VizSensor from "react-visibility-sensor";
import Heading from "../../components/Heading/Heading";
import useTranslations from "../../hoc/useTranslations";
import Button from "../../components/Button/Button";
import { useMatchMedia } from "../../hoc/useMatchMedia";

import video1 from "../../assets/videos/video_1.mp4";
import video2 from "../../assets/videos/video_2.mp4";
import video3 from "../../assets/videos/video_3.mp4";
import video4 from "../../assets/videos/video_4.mp4";
import video5 from "../../assets/videos/video_5.mp4";
import video6 from "../../assets/videos/video_6.mp4";
import video7 from "../../assets/videos/video_7.mp4";
import video8 from "../../assets/videos/video_8.mp4";
import video9 from "../../assets/videos/video_9.mp4";

import "./ProductDetails.scss";

const videos = [
	[
		{ src: video1, type: "video" },
		{ src: video2, type: "video" },
		{ src: video3, type: "video" },
	],
	[
		{ src: video4, type: "video" },
		{ src: video5, type: "video" },
		{ src: video6, type: "video" },
	],
	[
		{ src: video7, type: "video" },
		{ src: video8, type: "video" },
		{ src: video9, type: "video" },
	],
];

const ProductDetailsSection = ({ videos, items }) => {
	const [activeMini, setActiveMini] = useState(0);
	const [mobileVideoActive, setMobileVideoActive] = useState(false);

	const {
		product: {
			product_details: { watch_video },
		},
	} = useTranslations();

	const matchesMedia = useMatchMedia("min-width: 992px");

	const videoContainerRef = useRef(null);
	const buttonRef = useRef(null);

	const handleMiniActivation = useCallback(
		index => {
			if (matchesMedia) return setActiveMini(index);

			return undefined;
		},
		[matchesMedia],
	);

	const handleButtonActivation = useCallback(
		index => {
			if (!matchesMedia) {
				setMobileVideoActive(true);
				return setActiveMini(index);
			}

			return undefined;
		},
		[matchesMedia],
	);

	const handleOutsideClick = useCallback(
		e => {
			if (
				mobileVideoActive &&
				!videoContainerRef.current.contains(e.target) &&
				!buttonRef.current.contains(e.target)
			) {
				setMobileVideoActive(false);
				setActiveMini(0);
			}
		},
		[mobileVideoActive],
	);

	useEffect(() => {
		window.addEventListener("click", handleOutsideClick);

		return () => {
			window.removeEventListener("click", handleOutsideClick);
		};
	}, [handleOutsideClick]);

	useEffect(() => {
		setActiveMini(0);
		setMobileVideoActive(false);
	}, [matchesMedia]);

	const videoRef = useRef(null);

	const handleSensorChange = useCallback(
		isVisible => {
			if (isVisible) {
				videoRef?.current?.play();
			} else {
				videoRef?.current?.pause();
			}
		},
		[activeMini],
	);

	return (
		<>
			<div className="product-details__articles">
				<div className="product-details__articles--container">
					{items.map(({ title, description }, index) => (
						<article
							key={title + index}
							className={clsx("product-details__article", activeMini === index && "active")}
							onClick={() => handleMiniActivation(index)}
							tabIndex="0"
						>
							<Heading type={4} className="product-details__article--title">
								{title}
							</Heading>
							<p className="product-details__article--content">{description}</p>
							<Button
								onClick={() => handleButtonActivation(index)}
								classNames={["product-details__article--button"]}
								ref={buttonRef}
							>
								{watch_video}
							</Button>
						</article>
					))}
				</div>
				<div
					className={clsx("product-details__video", !matchesMedia && mobileVideoActive && "active")}
				>
					<div className="product-details__video--container" ref={videoContainerRef}>
						<VizSensor onChange={handleSensorChange}>
							<video
								className="product-details__video--file"
								src={videos[activeMini].src}
								controls
								muted
								loop
								autoPlay
								ref={videoRef}
							/>
						</VizSensor>
					</div>
					<div className="product-details__video--rectangle"></div>
				</div>
			</div>
		</>
	);
};

ProductDetailsSection.propTypes = {
	title: PropTypes.string.isRequired,
	activate: PropTypes.func.isRequired,
	activeTab: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	videos: PropTypes.array.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			description: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}).isRequired,
	).isRequired,
};

const ProductDetails = () => {
	const {
		product: {
			product_details: { sections },
		},
	} = useTranslations();

	const details_sections = sections.map((item, index) => ({
		...item,
		videos: videos[index],
	}));

	return (
		<div className="section section__product-details">
			{details_sections.map(({ title, items, videos }) => (
				<section key={title} className="product-details__section">
					<Heading type={2} className="product-details__section--title">
						{title}
					</Heading>
					<ProductDetailsSection items={items} videos={videos} />
				</section>
			))}
		</div>
	);
};

export default ProductDetails;
