import { useEffect, useState } from "react";
import { isServer } from "../helpers/helpers";

/**
 *
 * @param {string} media query paramater 'min-width: XXpx' || 'max-width: XXpx'
 */

export const useMatchMedia = media => {
	const [matchesMedia, setMatchesMedia] = useState(false);

	const matchMediaQuery = isServer() ? null : window?.matchMedia(`(${media})`);

	const match = e => {
		if (e.matches) {
			return setMatchesMedia(true);
		}

		return setMatchesMedia(false);
	};

	useEffect(() => {
		setMatchesMedia(matchMediaQuery.matches);

		if (matchMediaQuery) {
			matchMediaQuery.addEventListener?.("change", match);
		}

		return () => matchMediaQuery?.removeEventListener?.("change", match);
	}, []);

	return matchesMedia;
};
